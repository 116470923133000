exports.list = {
  1: ["NICOBAR", "NORTH AND MIDDLE ANDAMAN", "SOUTH ANDAMAN", "OTHER"],
  2: [
    "ANANTHAPUR",
    "CHITTOOR",
    "CUDDAPAH",
    "EAST GODAVARI",
    "EAST GODAVARI DISTRICT",
    "GUNTUR",
    "K. V. RANGAREDDY",
    "KRISHNA",
    "KURNOOL",
    "MAHABUB NAGAR",
    "MAHABUBNAGAR",
    "MAHABULNAGAR ",
    "MAHBUBNAGAR",
    "NELLORE",
    "PEDDAPALLI",
    "PRAKASAM",
    "RANGAREDDI",
    "SECUNDERABAD",
    "SRIKAKULAM",
    "VISAKHAPATNAM",
    "VIZIANAGARAM",
    "WEST GODAVARI",
    "WESTGODAVARI",
    "OTHER",
  ],
  3: [
    "CHANGLANG",
    "DIBANG VALLEY",
    "EAST KAMENG",
    "EAST SIANG",
    "KURUNG KUMEY",
    "LOHIT",
    "LONGDING",
    "LOWER DIBANG VALLEY",
    "LOWER SUBANSIRI",
    "NAMSAI",
    "PAPUM PARE",
    "TAWANG",
    "TIRAP",
    "UPPER SIANG",
    "UPPER SUBANSIRI",
    "WEST KAMENG",
    "WEST SIANG",
    "WESTSIANG",
    "OTHER",
  ],
  4: [
    "BAKSA",
    "BARPETA",
    "BONGAIGAON",
    "CACHAR",
    "CHIRANG",
    "DARRANG",
    "DHEMAJI",
    "DHUBRI",
    "DIBRUGARH",
    "GOALPARA",
    "GOLAGHAT",
    "HAILAKANDI",
    "HOJAI",
    "JORHAT",
    "KAMRUP",
    "KAMRUP (METRO)",
    "KARBI ANGLONG",
    "KARIMGANJ",
    "KOKRAJHAR",
    "LAKHIMPUR",
    "MARIGAON",
    "NAGAON",
    "NALBARI",
    "NORTH CACHAR HILLS",
    "SIBSAGAR",
    "SONITPUR",
    "TINSUKIA",
    "TIRAP",
    "UDALGURI",
    "OTHER",
  ],
  5: [
    "ARA",
    "ARARIA",
    "ARWAL",
    "AURANGABAD",
    "AURANGABAD(BH)",
    "BANKA",
    "BEGUSARAI",
    "BHAGALPUR",
    "BHOJPUR",
    "BUXAR",
    "DARBHANGA",
    "EAST CHAMPARAN",
    "GAYA",
    "GOPAL GANJ",
    "GOPALGANJ",
    "JAMUI",
    "JEHANABAD",
    "KAIMUR (BHABUA)",
    "KATIHAR",
    "KHAGARIA",
    "KISHANGANJ",
    "LAKHISARAI",
    "LAKHISARAL",
    "MADHEPURA",
    "MADHUBANI",
    "MUNGER",
    "MUSAFFARPUR",
    "MUZAFFARPUR",
    "NALANDA",
    "NAWADA",
    "PATNA",
    "PURNIA",
    "ROHTAS",
    "SAHARSA",
    "SAMASTIPUR",
    "SARAN",
    "SHEIKHPURA",
    "SHEKH PURE",
    "SHEOHAR ",
    "SITAMARHI",
    "SIWAN",
    "SUPAUL",
    "VAISHALI",
    "WEST CHAMPARAN",
    "OTHER",
  ],
  6: ["CHANDIGARH", "OTHER"],
  33: [
    "BALOD",
    "BALODA BAZAR",
    "BALRAMPUR",
    "BASTAR",
    "BEMETARA",
    "BHATAPARA",
    "BIJAPUR(CGH)",
    "BILASPUR",
    "BILASPUR(CGH)",
    "DANTEWADA",
    "DHAMTARI",
    "DURG",
    "GARIABAND",
    "JANJGIR-CHAMPA",
    "JASHPUR",
    "KABEERDHAM",
    "KABIRDHAM",
    "KANKER",
    "KAWARDHA",
    "KONDAGAON",
    "KORBA",
    "KORIYA",
    "MAHASAMUND",
    "MUNGELI",
    "NARAYANPUR",
    "RAIGARH",
    "RAIPUR",
    "RAJNANDGAON",
    "RIPUR",
    "SUKMA",
    "SURAJPUR",
    "SURGUJA",
    "OTHER",
  ],
  7: ["DADRA & NAGAR HAVELI", "OTHER"],
  8: ["DAMAN", "DIU", "OTHER"],
  9: [
    "CENTRAL DELHI",
    "CHATTARPUR",
    "EAST DELHI",
    "NEW DELHI",
    "NORTH DELHI",
    "NORTH EAST DELHI",
    "NORTH WEST DELHI",
    "SOUTH DELHI",
    "SOUTH WEST DELHI",
    "WEST DELHI",
    "OTHER",
  ],
  10: ["NORTH GOA", "SOUTH GOA", "OTHER"],
  11: [
    "AHMEDABAD",
    "AMRELI",
    "ANAND",
    "ARAVALLI",
    "ARVALLI",
    "BANASKANTHA",
    "BHARUCH",
    "BHAVNAGAR",
    "DAHOD",
    "DEVBHUMI DWARKA",
    "GANDHI NAGAR",
    "GIR SOMNATH",
    "JAMNAGAR",
    "JUNAGADH",
    "KACHCHH",
    "KHEDA",
    "MAHESANA",
    "MORBI",
    "NARMADA",
    "NAVSARI",
    "PANCH MAHALS",
    "PATAN",
    "PORBANDAR",
    "RAJKOT",
    "SABARKANTHA",
    "SURAT",
    "SURENDRA NAGAR",
    "TAPI",
    "THE DANGS",
    "VADODARA",
    "VALSAD",
    "OTHER",
  ],
  12: [
    "AMBALA",
    "BHIWANI",
    "FARIDABAD",
    "FATEHABAD",
    "GURGAON",
    "HISAR",
    "JHAJJAR",
    "JIND",
    "KAITHAL",
    "KARNAL",
    "KURUKSHETRA",
    "MAHENDRAGARH",
    "NUH MEWAT",
    "NUH, MEWAT",
    "PALWAL",
    "PANCHKULA",
    "PANIPAT",
    "REWARI",
    "ROHTAK",
    "SIRSA",
    "SONIPAT",
    "YAMUNA NAGAR",
    "OTHER",
  ],
  13: [
    "BILASPUR (HP)",
    "CHAMBA",
    "HAMIRPUR(HP)",
    "KANGRA",
    "KINNAUR",
    "KULLU",
    "LADHAROLE ",
    "LAHUL & SPITI",
    "MANDI",
    "SHIMLA",
    "SIRMAUR",
    "SOLAN",
    "UNA",
    "OTHER",
  ],
  14: [
    "LEH",
    "ANANTHNAG",
    "BANDIPUR",
    "BARAMULLA",
    "BUDGAM",
    "DODA",
    "GANDERBAL",
    "JAMMU",
    "KARGIL",
    "KATHUA",
    "KISHTWAR ",
    "KULGAM",
    "KUPWARA",
    "LEH",
    "POONCH",
    "PULWAMA",
    "RAJAURI",
    "RAMBAN",
    "REASI",
    "SAMBA",
    "SHOPIAN",
    "SRINAGAR",
    "UDHAMPUR",
    "OTHER",
  ],
  35: [
    "BOKARO",
    "CHATRA",
    "DEOGHAR",
    "DHANBAD",
    "DUMKA",
    "EAST SINGHBHUM",
    "GARHWA",
    "GIRIDH",
    "GODDA",
    "GUMIA",
    "GUMLA",
    "HAZARIBAG",
    "HAZARIBAGH",
    "JAMTARA",
    "KHUNTI",
    "KODERMA",
    "LATEHAR",
    "LOHARDAGA",
    "PACHRA",
    "PAKUR",
    "PALAMAU",
    "PALAMU",
    "RAMGARH",
    "RANCHI",
    "SAHIBGANJ",
    "SERAIKELA-KHARSAWAN",
    "SIMDEGA",
    "WEST SINGHBHUM",
    "OTHER",
  ],
  15: [
    "BAGALKOT",
    "BALLARI",
    "BANGALORE",
    "BANGALORE RURAL",
    "BANGALORE URBAN",
    "BELAGAVI",
    "BENGALURU",
    "BIDAR",
    "BIJAPUR",
    "CHAMRAJNAGAR",
    "CHIKKABALLAPUR",
    "CHIKKAMAGALURU",
    "CHITRADURGA",
    "DAKSHINA KANNADA",
    "DAVANGERE",
    "DHARWAD",
    "GADAG",
    "HASSAN",
    "HAVERI",
    "KALABURAGI",
    "KODAGU",
    "KOLAR",
    "KOPPAL",
    "MAGALORE",
    "MANDYA",
    "MYSORE",
    "MYSURU",
    "RAICHUR",
    "RAMANAGAR",
    "SHIMOGA",
    "SHIVAMOGGA",
    "TUMAKURU",
    "UDUPI",
    "UTTARA KANNADA",
    "VIJAYAPURA",
    "YADGIR",
    "OTHER",
  ],
  16: [
    "ALAPPUZHA",
    "ERNAKULAM",
    "IDUKKI",
    "KANNUR",
    "KASARGOD",
    "KOLLAM",
    "KOTTAYAM",
    "KOZHIKODE",
    "MALAPPURAM",
    "PALAKKAD",
    "PATHANAMTHITTA",
    "THIRUVANANTHAPURAM",
    "THRISSUR",
    "WAYANAD",
    "OTHER",
  ],
  37: ["LEH", "OTHER"],
  17: ["LAKSHADWEEP", "OTHER"],
  18: [
    "AGAR MALWA",
    "ALIRAJPUR",
    "ANUPPUR",
    "ASHOK NAGAR",
    "BALAGHAT",
    "BANJARI CHORAHA",
    "BARWANI",
    "BETUL",
    "BHIND",
    "BHOPAL",
    "BURHANPUR",
    "CHHATARPUR",
    "CHHINDWARA",
    "DAMOH",
    "DATIA",
    "DEWAS",
    "DHAR",
    "DINDORI",
    "EAST NIMAR",
    "GUNA",
    "GWALIOR",
    "HARDA",
    "HOSHANGABAD",
    "INDORE",
    "JABALPUR",
    "JHABUA",
    "KATNI",
    "KHANDWA",
    "KHARGONE",
    "MANDLA",
    "MANDSAUR",
    "MORENA",
    "NARSINGHPUR",
    "NEEMUCH",
    "NIWARI",
    "OTHER",
    "PANNA",
    "RAISEN",
    "RAJGARH",
    "RATLAM",
    "REWA",
    "SAGAR",
    "SAINKHEDA",
    "SATNA",
    "SEHORE",
    "SEONI",
    "SHAHDOL",
    "SHAJAPUR",
    "SHEOPUR",
    "SHIVPURI",
    "SIDHI",
    "SINGRAULI",
    "TIKAMGARH",
    "UJJAIN",
    "UMARIA",
    "VIDISHA",
    "WEST NIMAR",
    "OTHER",
  ],
  19: [
    "AHMED NAGAR",
    "AKOLA",
    "AMRAVATI",
    "AURANGABAD",
    "BEED",
    "BHANDARA",
    "BULDHANA",
    "CHANDRAPUR",
    "DHULE",
    "GADCHIROLI",
    "GONDIA",
    "HINGOLI",
    "JALGAON",
    "JALGOAN",
    "JALNA",
    "KHARGONE",
    "KOLHAPUR",
    "LATUR",
    "MUMBAI",
    "NAGPUR",
    "NANDED",
    "NANDURBAR",
    "NASHIK",
    "OSMANABAD",
    "PALGHAR",
    "PARBHANI",
    "PUNE",
    "RAIGARH(MH)",
    "RATNAGIRI",
    "SANGALI",
    "SANGLI",
    "SATARA",
    "SINDHUDURG",
    "SOLAPUR",
    "THANE",
    "WARDHA",
    "WASHIM",
    "YAVATMAL",
    "OTHER",
  ],
  20: [
    "BISHNUPUR",
    "CHANDEL",
    "CHURACHANDPUR",
    "IMPHAL EAST",
    "IMPHAL WEST",
    "JARIBAM",
    "KAKCHING",
    "KANGPOKPI",
    "SENAPATI",
    "TAMENGLONG",
    "TENGNOUPAL",
    "THOUBAL",
    "UKHRUL",
    "OTHER",
  ],
  21: [
    "EAST GARO HILLS",
    "EAST KHASI HILLS",
    "JAINTIA HILLS",
    "RI BHOI",
    "SOUTH GARO HILLS",
    "WEST GARO HILLS",
    "WEST KHASI HILLS",
    "OTHER",
  ],
  22: [
    "AIZAWL",
    "CHAMPHAI",
    "KOLASIB",
    "LAWNGTLAI",
    "LUNGLEI",
    "MAMMIT",
    "SAIHA",
    "SERCHHIP",
    "OTHER",
  ],
  23: [
    "DIMAPUR",
    "KIPHIRE",
    "KOHIMA",
    "LONGLENG",
    "MOKOKCHUNG",
    "MON",
    "PEREN",
    "PHEK",
    "TUENSANG",
    "WOKHA",
    "ZUNHEBOTTO",
    "OTHER",
  ],
  24: [
    "ANGUL",
    "BALANGIR",
    "BALESHWAR",
    "BALESWAR",
    "BARGARH",
    "BHADRAK",
    "BOUDH",
    "CUTTACK",
    "DEBAGARH",
    "DHENKANAL",
    "GAJAPATI",
    "GANJAM",
    "JAGATSINGHAPUR",
    "JAGATSINGHPUR",
    "JAJAPUR",
    "JAJPUR",
    "JHARSUGUDA",
    "KALAHANDI",
    "KANDHAMAL",
    "KENDRAPARA",
    "KENDUJHAR",
    "KEONJHARGARH",
    "KHORDA",
    "KHORDHA",
    "KHURDA",
    "KHURDHA",
    "KORAPUT",
    "MALKANGIRI",
    "MAYURBHANJ",
    "NABARANGAPUR",
    "NAYAGARH",
    "NUAPADA",
    "PURI",
    "RANJAN DAS",
    "RAYAGADA",
    "SAMBALPUR",
    "SONAPUR",
    "SUNDERGARH",
    "OTHER",
  ],
  25: ["KARAIKAL", "MAHE", "PONDICHERRY", "OTHER"],
  26: [
    "AMRITSAR",
    "BARNALA",
    "BATHINDA",
    "FARIDKOT",
    "FATEHGARH SAHIB",
    "FAZILKA",
    "FIROZPUR",
    "GURDASPUR",
    "HOSHIARPUR",
    "JALANDHAR",
    "KAPURTHALA",
    "LUDHIANA",
    "MANSA",
    "MOGA",
    "MOHALI",
    "MUKTSAR",
    "NAWANSHAHR",
    "PATHANKOT",
    "PATIALA",
    "ROPAR",
    "RUPNAGAR",
    "SANGRUR",
    "SBS NAGAR",
    "TARN TARAN",
    "OTHER",
  ],
  27: [
    "AJMER",
    "ALWAR",
    "BANSWARA",
    "BARAN",
    "BARMER",
    "BHARATPUR",
    "BHILWARA",
    "BIKANER",
    "BUNDI",
    "CHITTORGARH",
    "CHURU",
    "DAUSA",
    "DHOLPUR",
    "DUNGARPUR",
    "GANGANAGAR",
    "HANUMANGARH",
    "JAIPUR",
    "JAISALMER",
    "JALOR",
    "JHALAWAR",
    "JHUJHUNU",
    "JODHPUR",
    "KARAULI",
    "KOTA",
    "NAGAUR",
    "PALI",
    "PRATAPGARH",
    "RAJSAMAND",
    "SAWAI MADHOPUR",
    "SHIV GANJ",
    "SIKAR",
    "SIROHI",
    "TONK",
    "UDAIPUR",
    "OTHER",
  ],
  28: ["EAST SIKKIM", "NORTH SIKKIM", "SOUTH SIKKIM", "WEST SIKKIM", "OTHER"],
  29: [
    "ARIYALUR",
    "CHENNAI",
    "COIMBATORE",
    "CUDDALORE",
    "DHARMAPURI",
    "DINDIGUL",
    "ERODE",
    "KANCHIPURAM",
    "KANYAKUMARI",
    "KARUR",
    "KRISHNAGIRI",
    "MADURAI",
    "NAGAPATTINAM",
    "NAMAKKAL",
    "NILGIRI",
    "NILGIRIS",
    "PERAMBALUR",
    "PONDICHERRY",
    "PUDUKKOTTAI",
    "RAMANATHAPURAM",
    "SALEM",
    "SIVAGANGA",
    "THANJAVUR",
    "THENI",
    "THIRUVALLUR",
    "TIRUCHENDUR",
    "TIRUCHIRAPPALLI",
    "TIRUNELVELI",
    "TIRUPPUR",
    "TIRUVALLUR",
    "TIRUVANNAMALAI",
    "TIRUVARUR",
    "TUTICORIN",
    "VELLORE",
    "VILLUPURAM",
    "VIRUDHUNAGAR",
    "OTHER",
  ],
  36: [
    "ADILABAD",
    "BHADRADRI KOTHAGUDEM",
    "HYDERABAD",
    "JAYASHANKAR",
    "K.V. RANGAREDDY",
    "K.V.RANGAREDDY",
    "KARIM NAGAR",
    "KHAMMAM",
    "MAHABUB NAGAR",
    "MAHABUBABAD",
    "MEDAK",
    "MEDCHAL",
    "NALGONDA",
    "NIZAMABAD",
    "RAJANNA SIRCILLA",
    "RANGAREDDY",
    "SIDDIPET",
    "SURYAPET",
    "WARANGAL",
    "OTHER",
  ],
  30: [
    "DHALAI",
    "GOMTI",
    "KHOWAI",
    "NORTH TRIPURA",
    "SEPAHIJALA",
    "SOUTH TRIPURA",
    "WEST TRIPURA",
    "OTHER",
  ],
  31: [
    "AGRA",
    "ALIGARH",
    "ALIGHAR",
    "ALLAHABAD",
    "AMBEDKAR NAGAR",
    "AMETHI",
    "AMROHA",
    "AURAIYA",
    "AZAMGARH",
    "BAGHPAT",
    "BAGPAT",
    "BAHRAICH",
    "BALLIA",
    "BALRAMPUR",
    "BANDA",
    "BARABANKI",
    "BAREILLY",
    "BASTI",
    "BHAGHPAT",
    "BIJNOR",
    "BUDAUN",
    "BULANDSHAHR",
    "CHANDAULI",
    "CHITRAKOOT",
    "DEORIA",
    "ETAH",
    "ETAWAH",
    "FAIZABAD",
    "FARRUKHABAD",
    "FARUKHABAD",
    "FATEHPUR",
    "FIROZABAD",
    "GAURIGANJ",
    "GAUTAM BUDDHA NAGAR",
    "GAUTAMBUDH NAGAR",
    "GHAZIABAD",
    "GHAZIPUR",
    "GONDA",
    "GORAKHPUR",
    "HAMIRPUR",
    "HAPUR",
    "HARDOI",
    "HATHRAS",
    "HETIMPUR",
    "JALAUN",
    "JAUNPUR",
    "JHANSI",
    "JYOTIBA PHULE NAGAR",
    "KANNAUJ",
    "KANPUR DEHAT",
    "KANPUR NAGAR",
    "KASGANJ",
    "KATIHAL",
    "KAUSHAMBI",
    "KHERI",
    "KHURD",
    "KUSHINAGAR",
    "LALITPUR",
    "LUCKNOW",
    "MAHARAJGANJ",
    "MAHOBA",
    "MAINPURI",
    "MATHURA",
    "MAU",
    "MAU, ",
    "MEERUT",
    "MIRZAPUR",
    "MORADABAD",
    "MOU",
    "MUZAFFARNAGAR",
    "PILIBHIT",
    "PRATAPGARH",
    "RAEBARELI",
    "RAMPUR",
    "SAHARANPUR",
    "SAMBHAL",
    "SAMDHAL",
    "SANT KABIR NAGAR",
    "SANT RAVIDAS NAGAR",
    "SHAHJAHANPUR",
    "SHAMLI",
    "SHRAWASTI",
    "SIDDHARTHNAGAR",
    "SITAPUR",
    "SONBHADRA",
    "SULTANPUR",
    "THARI UNNAO",
    "UNNAO",
    "VARANASI",
    "OTHER",
  ],
  34: [
    "ALMORA",
    "BAGESHWAR",
    "CHAMOLI",
    "CHAMPAWAT",
    "DEHRADUN",
    "HARIDWAR",
    "NAINITAL",
    "PAURI GARHWAL",
    "PITHORAGARH",
    "RUDRAPRAYAG",
    "RUDRAPUR ",
    "TEHRI GARHWAL",
    "UDHAM SINGH NAGAR",
    "UTTARKASHI",
    "OTHER",
  ],
  32: [
    " JALPAIGURI/DARJEELING",
    " JAPAIGURI AND ALIPURDUAR",
    "24 PARGANAS (S)",
    "24 PGS NORTH",
    "ALIPURDUAR",
    "BANKURA",
    "BARDDHAMAN",
    "BARDHAMAN",
    "BIRBHUM",
    "BURDWAN",
    "COOCH BEHAR",
    "COOCHBEHAR",
    "DAKSHIN DINAJPUR",
    "DARJEELING",
    "DARJILING",
    "DEBAGARH",
    "EAST MEDINIPUR",
    "EAST MIDNAPORE",
    "EASTMIDNAPORE",
    "HOOGHLY",
    "HOWRAH",
    "JALPAIGURI",
    "JALPAIGURI/DARJEELING",
    "JAPAIGURI AND ALIPURDUAR",
    "JHARGRAM",
    "KALIMPONG",
    "KOLKATA",
    "MALDA",
    "MEDINIPUR",
    "MURSHIDABAD",
    "MUSHIDABAD",
    "NADIA",
    "NORTH 24  PARGANAS",
    "NORTH 24 PARGANAS",
    "NORTH 24 PGS",
    "NORTH DINAJPUR",
    "NORTH SIKKIM",
    "NORTHDINAJPUR",
    "PACHIM MEDIINIPUR",
    "PASCHIIM BARDDHAMAN",
    "PASCHIM BARDDHAMAN",
    "PASCHIM BARDHAMAN",
    "PASCHIM BURDWAN",
    "PASCHIM MEDINAPUR",
    "PASCHIM MEDINIPUR",
    "PASCHIM MIDNAPORE",
    "PURBA BARDDHAMAN",
    "PURBA BARDHAMAN",
    "PURBA BURDWAN",
    "PURBA MEDIBIPUR",
    "PURBA MEDINIPUR",
    "PURBAMEDINIPUR",
    "PURULIA",
    "PURULIYA",
    "RAIGANJ",
    "RISHRA",
    "SILIGURI",
    "SOUTH ,24 PARGANAS",
    "SOUTH 24 PARAGNAS",
    "SOUTH 24 PARGANAS",
    "SOUTH 24 PGS",
    "SOUTH DINAJPUR",
    "UTTAR DINAJPUR",
    "WEST MEDINIPUR",
    "WEST MIDNAPORE",
    "OTHER",
  ],
};
