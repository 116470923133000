import React, { useState, useEffect, useContext } from "react";
import DataTableComponent from "react-data-table-component";

import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Swal from "sweetalert2";

import Templete from "../../Templete/Templete";
import AuthService from "../../../../services/User/AuthService";
import UtiitslPANCardService from "../../../../services/User/UtiitslPANCardService";
import HttpError from "../../../../utill/HttpError";
import LoadingContext from "../../../../store/loading-context";

const UtiV2UploadPending = () => {
  const loadingCtx = useContext(LoadingContext);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // page, size, searchText, fromDate, toDate, status, productId

  const fetchData = async (_status) => {
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await UtiitslPANCardService.pendingDoc(token);

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        setData(responseData.data);
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  const columns = [
    {
      name: "Application No",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row["Application No"]}</span>
        </div>
      ),
    },
    {
      name: "Applicant Name",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row["Applicant Name"]}</span>
        </div>
      ),
    },
    {
      name: "Date of Birth",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.DOB}</span>
        </div>
      ),
    },
    {
      name: "Action",
      cell: (row) => (
        <div>
          <button
            className="btn btn-info btn-sm"
            onClick={() => {
              uploadHandler(row["Application No"]);
            }}
          >
            <i className="fa fa-upload"></i> Upload Docs
          </button>
        </div>
      ),
    },
  ];

  const uploadHandler = async (couponNo) => {
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await UtiitslPANCardService.pendingUpload(
        token,
        couponNo
      );

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        const redirectUrl = responseData.data.redirect_url;

        window.open(redirectUrl, "_blank");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  return (
    <React.Fragment>
      <Templete t1="Home" t2="Upload Pending">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-default">
                  <div class="card-header">
                    <h3 class="card-title">Upload Pending</h3>
                  </div>

                  <div class="card-body">
                    <DataTableComponent
                      noHeader={true}
                      columns={columns}
                      data={data}
                      progressPending={loading}
                      highlightOnHover
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Templete>
    </React.Fragment>
  );
};

export default UtiV2UploadPending;
