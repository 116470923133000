import React, { useRef, useContext, useState } from "react";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";

import Templete from "../../Templete/Templete";

import AuthService from "../../../../services/User/AuthService";
import UtiitslPANCardService from "../../../../services/User/UtiitslPANCardService";
import HttpError from "../../../../utill/HttpError";
import LoadingContext from "../../../../store/loading-context";

const FindPanNumber = () => {
  const loadingCtx = useContext(LoadingContext);
  const history = useHistory();

  const formRef = useRef();
  const aadhaarRef = useRef();

  const submitHandler = async () => {
    const aadhaaar = aadhaarRef.current.value;

    if (!aadhaaar) {
      Swal.fire("Opps!", "Invalid Aadhaar Number", "error");
      return;
    }

    if (aadhaaar.length < 12 || aadhaaar.length > 12) {
      Swal.fire("Opps!", "Invalid Aadhaar Number", "error");
      return;
    }

    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await UtiitslPANCardService.panFind(token, aadhaaar);

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        formRef.current.reset();
        Swal.fire("Success!", message, "success");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  return (
    <React.Fragment>
      <Templete t1="Home" t2="Find PAN Number">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-default">
                  <div class="card-header">
                    <h3 class="card-title">Find PAN Number</h3>
                    <div class="card-tools">
                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        onClick={() => {
                          history.push("/user/pan_find_report");
                        }}
                      >
                        Report
                      </button>
                    </div>
                  </div>
                  <div class="row pt-3 pb-3">
                    <div class="col-md-6 mx-auto">
                      <form ref={formRef} autoComplete="off">
                        <div class="card-body">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">
                              Aadhaar Number <span className="red">*</span>
                            </label>
                            <div class="col-sm-9">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Aadhaar Number"
                                ref={aadhaarRef}
                              />
                            </div>
                          </div>

                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">
                              Important Note:
                            </label>
                            <div class="col-sm-9">
                              <p className="red">
                                You will be charged Rs 49.00/- per successful
                                PAN find. The amount will be debited from your
                                wallet.
                              </p>
                            </div>
                          </div>

                          <div class="text-center pt-2">
                            <button
                              type="button"
                              class="btn btn-primary"
                              onClick={submitHandler}
                            >
                              Pay & Find PAN
                            </button>
                            <button type="reset" class="btn btn-default ml-5">
                              Reset
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Templete>
    </React.Fragment>
  );
};

export default FindPanNumber;
