import React, { useContext, useEffect, useState } from "react";
import Swal from "sweetalert2";
import classnames from "classnames";
import {
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ListGroup,
  ListGroupItem,
  Label,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Spinner,
} from "reactstrap";
import Select from "react-select";

import AuthService from "../../../../services/User/AuthService";
import RechargeService from "../../../../services/User/RechargeService";
import HttpError from "../../../../utill/HttpError";
import LoadingContext from "../../../../store/loading-context";

const RofferDialog = (props) => {
  const data = props.data.data;

  return (
    <React.Fragment>
      <Modal size="lg" isOpen={props.open} toggle={props.handleClose}>
        <div className="modal-header">
          <h5 className="modal-title" id="myModalLabel">
            ROFFERS
          </h5>
          <img
            src={props.operator ? props.operator.image : ""}
            alt="opt-logo"
            height="50px"
          />
        </div>

        <ModalBody>
          {" "}
          {data && (
            <table className="table table-bordered table-striped table-hover">
              <thead className="thead-light">
                <tr>
                  <th>Price</th>
                  <th>Short Description</th>
                  <th>Description</th>
                  <th>Select</th>
                </tr>
              </thead>
              <tbody>
                {data.map((plan, index) => (
                  <tr key={index}>
                    <td>
                      {" "}
                      <button
                        type="button"
                        class="btn btn-info btn-circle btn-sm"
                        onClick={() => {
                          props.handleSetAmount(plan.price);
                          props.handleClose();
                        }}
                      >
                        ₹{plan.price}
                      </button>
                    </td>
                    <td>{plan.short_description}</td>
                    <td>{plan.description}</td>
                    <td>
                      {" "}
                      <a
                        class="btn btn-outline-primary btn-sm"
                        onClick={() => {
                          props.handleSetAmount(plan.price);
                          props.handleClose();
                        }}
                      >
                        Choose
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="light" onClick={props.handleClose}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default RofferDialog;
