import common from "../../utill/Common";

class UtiPanCardService {
  report = async (
    token,
    from_date,
    to_date,
    status,
    user_id,
    search_text,
    page,
    size
  ) => {
    const url = common.baseUrl + "admin/uti_pancard/report";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        from_date,
        to_date,
        search_text,
        status,
        user_id,
        page,
        size,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  summary = async (token, from_date, to_date) => {
    const url = common.baseUrl + "admin/uti_pancard/summary";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({ from_date, to_date }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  updateTxnStatus = async (token, id, status, remark) => {
    const url = common.baseUrl + "admin/uti_pancard/update_txn_status";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({ id, status, remark }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  v2Report = async (
    token,
    from_date,
    to_date,
    status,
    user_id,
    search_text,
    page,
    size
  ) => {
    const url = common.baseUrl + "admin/uti_pancard/v2/report";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        from_date,
        to_date,
        search_text,
        status,
        user_id,
        page,
        size,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  v2summary = async (token, from_date, to_date) => {
    const url = common.baseUrl + "admin/uti_pancard/v2/summary";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({ from_date, to_date }),
    };

    const response = await fetch(url, options);

    return response.json();
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new UtiPanCardService();
