import React, { useContext, useEffect, useState } from "react";
import Swal from "sweetalert2";
import classnames from "classnames";
import {
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ListGroup,
  ListGroupItem,
  Label,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Spinner,
} from "reactstrap";
import Select from "react-select";

import AuthService from "../../../../services/User/AuthService";
import RechargeService from "../../../../services/User/RechargeService";
import HttpError from "../../../../utill/HttpError";
import LoadingContext from "../../../../store/loading-context";

const DthCustomerInfoDialog = (props) => {
  const data = props.data;

  return (
    <React.Fragment>
      <Modal size="lg" isOpen={props.open} toggle={props.handleClose}>
        <div className="modal-header">
          <h5 className="modal-title" id="myModalLabel">
            DTH CUSTOMER INFO
          </h5>
          <img
            src={props.operator ? props.operator.image : ""}
            alt="opt-logo"
            height="50px"
          />
        </div>

        <ModalBody>
          {data && (
            <table className="table table-bordered table-hover">
              <thead className="thead-light">
                <tr>
                  <th>Attribute</th>
                  <th>Details</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <strong>Customer Name</strong>
                  </td>
                  <td>{data.customer_name}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Balance</strong>
                  </td>
                  <td>{data.balance}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Next Recharge Date</strong>
                  </td>
                  <td>{data.next_recharge_date}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Status</strong>
                  </td>
                  <td>{data.status}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Plan Name</strong>
                  </td>
                  <td>{data.plan_name}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Monthly Recharge</strong>
                  </td>
                  <td>{data.monthly_recharge}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Last Recharge Amount</strong>
                  </td>
                  <td>{data.last_recharge_amount}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Last Recharge Date</strong>
                  </td>
                  <td>{data.last_recharge_date}</td>
                </tr>
              </tbody>
            </table>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="light" onClick={props.handleClose}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default DthCustomerInfoDialog;
