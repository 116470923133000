import common from "../../utill/Common";

class UtiitslPANCardService {
  generateLoginUrl = async (token) => {
    const url = common.baseUrl + "user/utiitsl_pancard/generate_login_url";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: null,
    };

    const response = await fetch(url, options);

    return response.json();
  };

  panAppReq = async (token, panData) => {
    const url = common.baseUrl + "user/uti_pancard/v2/pan_app_req";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({ panData: panData }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  objectionList = async (token) => {
    const url = common.baseUrl + "user/uti_pancard/v2/objection_list";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: null,
    };

    const response = await fetch(url, options);

    return response.json();
  };

  objectionEdit = async (token, coupon_no) => {
    const url = common.baseUrl + "user/uti_pancard/v2/objection_edit";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({ coupon_no: coupon_no }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  pendingDoc = async (token) => {
    const url = common.baseUrl + "user/uti_pancard/v2/pending_doc";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: null,
    };

    const response = await fetch(url, options);

    return response.json();
  };

  objectionDoc = async (token) => {
    const url = common.baseUrl + "user/uti_pancard/v2/objection_doc";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: null,
    };

    const response = await fetch(url, options);

    return response.json();
  };

  pendingUpload = async (token, coupon_no) => {
    const url = common.baseUrl + "user/uti_pancard/v2/pending_upload";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({ coupon_no: coupon_no }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  utiV2Report = async (
    token,
    from_date,
    to_date,
    status,
    search_text,
    page,
    size
  ) => {
    const url = common.baseUrl + "user/uti_pancard/v2/report";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        from_date,
        to_date,
        status,
        search_text,
        page,
        size,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  fetchByOrderId = async (token, order_id) => {
    const url = common.baseUrl + "user/uti_pancard/v2/fetch_by_order_id";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({ order_id: order_id }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  downloadForm = async (token, order_id) => {
    const url = common.baseUrl + "user/uti_pancard/v2/download_form";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({ order_id: order_id }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  downloadReceipt = async (token, order_id) => {
    const url = common.baseUrl + "user/uti_pancard/v2/download_receipt";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({ order_id: order_id }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  panFind = async (token, aadhaar) => {
    const url = common.baseUrl + "user/uti_pancard/pan_find";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({ aadhaar: aadhaar }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  panFindReport = async (
    token,
    from_date,
    to_date,
    status,
    search_text,
    page,
    size
  ) => {
    const url = common.baseUrl + "user/uti_pancard/pan_find_report";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        from_date,
        to_date,
        status,
        search_text,
        page,
        size,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new UtiitslPANCardService();
