import React, { useRef, useContext, useState } from "react";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";

import Templete from "../../Templete/Templete";

import AuthService from "../../../../services/User/AuthService";
import UtiitslPANCardService from "../../../../services/User/UtiitslPANCardService";
import HttpError from "../../../../utill/HttpError";
import LoadingContext from "../../../../store/loading-context";

import DistrictListFunction from "./DistrictList";

const UtiitslPanCardV2 = () => {
  const loadingCtx = useContext(LoadingContext);
  const history = useHistory();

  const formRef = useRef();

  const validateEmail = (input) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(input);
  };

  const validateMobile = (input) => {
    const mobileRegex = /^[6-9]\d{9}$/; // Starts with 6-9 and is 10 digits long
    return mobileRegex.test(input);
  };

  const submitHandler = async () => {
    const date = getFormattedDate();

    setPanData((prevData) => ({
      ...prevData,
      ["date"]: date, // Update the property dynamically based on the input's name
    }));

    if (panData.nameTitle == "") {
      Swal.fire("Opps!", "Please Select Title", "error");
      return;
    }

    if (panData.lastName == "") {
      Swal.fire("Opps!", "Please Enter Last Name", "error");
      return;
    }

    if (panData.panName == "") {
      Swal.fire("Opps!", "Please Enter Name On Card", "error");
      return;
    }

    if (panData.gender == "") {
      Swal.fire("Opps!", "Please Select Gender", "error");
      return;
    }

    if (panData.dobString == "") {
      Swal.fire("Opps!", "Please Enter Date of Birth", "error");
      return;
    }

    if (
      panData.aadhaarNo == "" ||
      panData.aadhaarNo.length < 12 ||
      panData.aadhaarNo.length > 12
    ) {
      Swal.fire("Opps!", "Please Enter Valid Aadhaar Number", "error");
      return;
    }

    if (!validateMobile(panData.telNo)) {
      Swal.fire("Opps!", "Please Enter Valid Mobile Number", "error");
      return;
    }

    if (!validateEmail(panData.emailId)) {
      Swal.fire("Opps!", "Please Enter Email Address", "error");
      return;
    }

    if (panData.fthLast == "") {
      Swal.fire("Opps!", "Please Enter Father's Last Name", "error");
      return;
    }

    if (
      panData.resAddr1 == "" ||
      panData.resAddr2 == "" ||
      panData.resAddr3 == "" ||
      panData.resAddr4 == "" ||
      panData.resDist == ""
    ) {
      Swal.fire("Opps!", "Please Enter Complete Address", "error");
      return;
    }

    if (
      panData.resPin == "" ||
      panData.resPin.length < 6 ||
      panData.resPin.length > 6
    ) {
      Swal.fire("Opps!", "Please Enter Valid PIN Code", "error");
      return;
    }

    if (panData.resState == "") {
      Swal.fire("Opps!", "Please Select State", "error");
      return;
    }

    // FOR MINOR CASA
    if (panData.minorCase == "Y") {
      //

      if (panData.repTitle == "") {
        Swal.fire("Opps!", "Please Select RA Title", "error");
        return;
      }

      if (panData.repLast == "") {
        Swal.fire("Opps!", "Please Enter RA Last name", "error");
        return;
      }

      if (
        panData.repAddr1 == "" ||
        panData.repAddr2 == "" ||
        panData.repAddr3 == "" ||
        panData.repAddr4 == "" ||
        panData.repDist == ""
      ) {
        Swal.fire("Opps!", "Please Enter RA Complete Address", "error");
        return;
      }

      if (
        panData.repPin == "" ||
        panData.repPin.length < 6 ||
        panData.repPin.length > 6
      ) {
        Swal.fire("Opps!", "Please Enter RA Valid PIN Code", "error");
        return;
      }

      if (panData.repState == "") {
        Swal.fire("Opps!", "Please Select RA State", "error");
        return;
      }

      //
    }

    loadingCtx.startLoading();

    // const district = panData.resDist;

    // const nameOnCard =
    //   panData.firstName + " " + panData.middleName + " " + panData.lastName;

    // const _panData = {
    //   ...panData,
    //   verName: nameOnCard,
    //   verPlace: district,
    // };

    try {
      const token = AuthService.getAccessToken();

      const responseData = await UtiitslPANCardService.panAppReq(
        token,
        panData
      );

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        // formRef.current.reset();
        Swal.fire("Success!", message, "success");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  const [panData, setPanData] = useState({
    nameTitle: "",
    lastName: "",
    firstName: "",
    middleName: "",
    panName: "",
    aadhaarName: "",
    gender: "",
    dobString: "",
    addrFlag: "A",
    resState: "",
    aadhaarNo: "",
    identityProofDoc: "16",
    addressProofDoc: "21",
    dobProofDoc: "21",
    telNo: "",
    minorCase: "N",
    emailId: "",
    fthLast: "",
    fthFirst: "",
    fthMiddle: "",
    resAddr1: "",
    resAddr2: "",
    resAddr3: "",
    resAddr4: "",
    resDist: "",
    resPin: "",
    repTitle: "",
    repLast: "",
    repFirst: "",
    repMiddle: "",
    repAddr1: "",
    repAddr2: "",
    repAddr3: "",
    repAddr4: "",
    repDist: "",
    repPin: "",
    repZip: "",
    repState: "",
    capacityCd: "1",
    verName: "",
    verPlace: "",
    state: "",
    date: "",
  });

  const getFormattedDate = () => {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, "0"); // Add leading zero
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const year = today.getFullYear();

    return `${day}-${month}-${year}`;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPanData((prevData) => ({
      ...prevData,
      [name]: value, // Update the property dynamically based on the input's name
    }));
  };

  const [districtList, setDistrictList] = useState();

  const handleGetDistrictList = (e) => {
    const { name, value } = e.target;

    const districts = DistrictListFunction.list;

    // Update the districts for the selected state
    if (districts[value]) {
      setDistrictList(districts[value]);
    } else {
      setDistrictList([]);
    }
  };

  const getName = (e) => {
    let fName = panData.firstName;
    let mName = panData.middleName;
    let lName = panData.lastName;

    const { name, value } = e.target;

    if (name == "firstName") {
      fName = value;
    }

    if (name == "middleName") {
      mName = value;
    }

    if (name == "lastName") {
      lName = value;
    }

    let fullname = fName + " " + mName + " " + lName;

    setPanData((prevData) => ({
      ...prevData,
      panName: fullname.trim(),
      aadhaarName: fullname.trim(),
    }));
  };

  const alertStyle = {
    backgroundColor: "#ffe4e1",
    border: "1px solid #ff0000",
    color: "#d8000c",
    padding: "15px",
    margin: "20px",
    borderRadius: "5px",
    fontFamily: "Arial, sans-serif",
    lineHeight: "1.5",
  };

  const strongStyle = {
    fontSize: "18px",
    marginBottom: "10px",
    display: "block",
  };

  return (
    <React.Fragment>
      <Templete t1="Home" t2="UTIITSL PAN CARD V2">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-default">
                  <div class="card-header">
                    <h3 class="card-title">UTIITSL PAN CARD V2</h3>
                    <div class="card-tools">
                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        onClick={() => {
                          history.push("/user/utiitsl_pancard_v2/report");
                        }}
                      >
                        Report
                      </button>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <form ref={formRef} autoComplete="off">
                        <div class="card-body">
                          <div className="row">
                            <div className="col-sm-4">
                              <div class="form-group">
                                <label for="exampleInputEmail1">
                                  Title <span className="red">*</span>
                                </label>
                                <select
                                  className="form-control uppercase"
                                  name="nameTitle"
                                  value={panData.nameTitle}
                                  onChange={handleInputChange}
                                >
                                  <option value="">Select Title</option>
                                  <option value="1">SHRI</option>
                                  <option value="2">SMT</option>
                                  <option value="3">KUMARI</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-4">
                              <div class="form-group">
                                <label>
                                  Last Name/Surname{" "}
                                  <span className="red">*</span>{" "}
                                </label>
                                <input
                                  type="text"
                                  class="form-control uppercase"
                                  placeholder="Last Name/Surname"
                                  name="lastName"
                                  value={panData.lastName}
                                  onChange={(e) => {
                                    handleInputChange(e);
                                    // getName(e);
                                  }}
                                />
                              </div>
                            </div>

                            <div className="col-sm-4">
                              <div class="form-group">
                                <label>First Name</label>
                                <input
                                  type="text"
                                  class="form-control uppercase"
                                  placeholder="First name"
                                  name="firstName"
                                  value={panData.firstName}
                                  onChange={(e) => {
                                    handleInputChange(e);
                                    //  getName(e);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div class="form-group">
                                <label>Middle Name</label>
                                <input
                                  type="text"
                                  class="form-control uppercase"
                                  placeholder="Middle name"
                                  name="middleName"
                                  value={panData.middleName}
                                  onChange={(e) => {
                                    handleInputChange(e);
                                    //  getName(e);
                                  }}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-sm-4">
                              <div class="form-group">
                                <label>
                                  Name on Card <span className="red">*</span>
                                </label>
                                <input
                                  type="text"
                                  class="form-control uppercase"
                                  placeholder="Name on Card"
                                  name="panName"
                                  value={panData.panName}
                                  onChange={handleInputChange}
                                />
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div class="form-group">
                                <label>
                                  Gender <span className="red">*</span>
                                </label>
                                <select
                                  className="form-control uppercase"
                                  name="gender"
                                  value={panData.gender}
                                  onChange={handleInputChange}
                                >
                                  <option value="">Select Gender</option>
                                  <option value="M">MALE</option>
                                  <option value="D">FEMALE</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div class="form-group">
                                <label>
                                  Date of Birth <span className="red">*</span>
                                </label>
                                <input
                                  type="date"
                                  class="form-control uppercase"
                                  name="dobString"
                                  value={panData.dobString}
                                  onChange={handleInputChange}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-sm-4">
                              <div class="form-group">
                                <label>
                                  Aadhaar Number <span className="red">*</span>
                                </label>
                                <input
                                  type="text"
                                  class="form-control uppercase"
                                  placeholder="Aadhaar Number"
                                  name="aadhaarNo"
                                  value={panData.aadhaarNo}
                                  onChange={handleInputChange}
                                  onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                  maxLength={12}
                                />
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div class="form-group">
                                <label>
                                  Name as per Aadhaar{" "}
                                  <span className="red">*</span>
                                </label>
                                <input
                                  type="text"
                                  class="form-control uppercase"
                                  placeholder="Name as per Aadhaar"
                                  name="panName"
                                  value={panData.panName}
                                  onChange={handleInputChange}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-sm-4">
                              <div class="form-group">
                                <label>
                                  Mobile Number <span className="red">*</span>
                                </label>
                                <input
                                  type="text"
                                  class="form-control uppercase"
                                  placeholder="Mobile Number"
                                  name="telNo"
                                  value={panData.telNo}
                                  onChange={handleInputChange}
                                  onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                  maxLength={10}
                                />
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div class="form-group">
                                <label>
                                  Email Address <span className="red">*</span>
                                </label>
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Email Address"
                                  name="emailId"
                                  value={panData.emailId}
                                  onChange={handleInputChange}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="pt-2 pb-3">
                            <hr />
                          </div>

                          <div className="row">
                            <div className="col-sm-4">
                              <div class="form-group">
                                <label>
                                  Father's Last Name{" "}
                                  <span className="red">*</span>
                                </label>
                                <input
                                  type="text"
                                  class="form-control uppercase"
                                  placeholder="Father's Last Name"
                                  name="fthLast"
                                  value={panData.fthLast}
                                  onChange={handleInputChange}
                                />
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div class="form-group">
                                <label>Father's First Name</label>
                                <input
                                  type="text"
                                  class="form-control uppercase"
                                  placeholder="Father's First Name"
                                  name="fthFirst"
                                  value={panData.fthFirst}
                                  onChange={handleInputChange}
                                />
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div class="form-group">
                                <label>Father's Middle Name</label>
                                <input
                                  type="text"
                                  class="form-control uppercase"
                                  placeholder="Father's Middle Name"
                                  name="fthMiddle"
                                  value={panData.fthMiddle}
                                  onChange={handleInputChange}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="pt-2 pb-3">
                            <hr />
                          </div>

                          <div className="row">
                            <div className="col-sm-4">
                              <div class="form-group">
                                <label>
                                  Flat/Room/Door/Block No{" "}
                                  <span className="red">*</span>
                                </label>
                                <input
                                  type="text"
                                  class="form-control uppercase"
                                  placeholder="Flat/Room/Door/Block No"
                                  name="resAddr1"
                                  value={panData.resAddr1}
                                  onChange={handleInputChange}
                                />
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div class="form-group">
                                <label>Building/Village Name</label>{" "}
                                <span className="red">*</span>
                                <input
                                  type="text"
                                  class="form-control uppercase"
                                  placeholder="Building/Village Name"
                                  name="resAddr2"
                                  value={panData.resAddr2}
                                  onChange={handleInputChange}
                                />
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div class="form-group">
                                <label>Road/Street/Lane/Post Office</label>{" "}
                                <span className="red">*</span>
                                <input
                                  type="text"
                                  class="form-control uppercase"
                                  placeholder="Road/Street/Lane/Post Office"
                                  name="resAddr3"
                                  value={panData.resAddr3}
                                  onChange={handleInputChange}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-sm-4">
                              <div class="form-group">
                                <label>Area/Locality/Sub-Division</label>{" "}
                                <span className="red">*</span>
                                <input
                                  type="text"
                                  class="form-control uppercase"
                                  placeholder="Area/Locality/Sub-Division"
                                  name="resAddr4"
                                  value={panData.resAddr4}
                                  onChange={handleInputChange}
                                />
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div class="form-group">
                                <label>
                                  State/Union Territory{" "}
                                  <span className="red">*</span>
                                </label>

                                <select
                                  className="form-control uppercase"
                                  name="resState"
                                  value={panData.resState}
                                  onChange={(e) => {
                                    handleInputChange(e);
                                    handleGetDistrictList(e);

                                    const selectedLabel =
                                      e.target.selectedOptions[0].text;

                                    if (selectedLabel) {
                                      let data = {
                                        target: {
                                          name: "state",
                                          value: selectedLabel,
                                        },
                                      };

                                      handleInputChange(data);
                                    }
                                  }}
                                >
                                  <option value="">Please Select</option>
                                  <option value="1">
                                    ANDAMAN AND NICOBAR ISLANDS
                                  </option>
                                  <option value="2">ANDHRA PRADESH</option>
                                  <option value="3">ARUNACHAL PRADESH</option>
                                  <option value="4">ASSAM</option>
                                  <option value="5">BIHAR</option>
                                  <option value="6">CHANDIGARH</option>
                                  <option value="33">CHHATTISGARH</option>
                                  <option value="7">
                                    DADRA AND NAGAR HAVELI
                                  </option>
                                  <option value="8">DAMAN AND DIU</option>
                                  <option value="9">DELHI</option>
                                  <option value="10">GOA</option>
                                  <option value="11">GUJARAT</option>
                                  <option value="12">HARYANA</option>
                                  <option value="13">HIMACHAL PRADESH</option>
                                  <option value="14">JAMMU AND KASHMIR</option>
                                  <option value="35">JHARKHAND</option>
                                  <option value="15">KARNATAKA</option>
                                  <option value="16">KERALA</option>
                                  <option value="37">LADAKH</option>
                                  <option value="17">LAKSHADWEEP</option>
                                  <option value="18">MADHYA PRADESH</option>
                                  <option value="19">MAHARASHTRA</option>
                                  <option value="20">MANIPUR</option>
                                  <option value="21">MEGHALAYA</option>
                                  <option value="22">MIZORAM</option>
                                  <option value="23">NAGALAND</option>
                                  <option value="24">ODISHA</option>
                                  <option value="99">OTHER</option>
                                  <option value="25">PONDICHERRY</option>
                                  <option value="26">PUNJAB</option>
                                  <option value="27">RAJASTHAN</option>
                                  <option value="28">SIKKIM</option>
                                  <option value="29">TAMIL NADU</option>
                                  <option value="36">TELANGANA</option>
                                  <option value="30">TRIPURA</option>
                                  <option value="31">UTTAR PRADESH</option>
                                  <option value="34">UTTARAKHAND</option>
                                  <option value="32">WEST BENGAL</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div class="form-group">
                                <label>Town/City/District</label>{" "}
                                <span className="red">*</span>
                                <select
                                  className="form-control uppercase"
                                  name="resDist"
                                  value={panData.resDist}
                                  onChange={handleInputChange}
                                >
                                  {districtList &&
                                    districtList.map((district, index) => (
                                      <option key={index} value={district}>
                                        {district}
                                      </option>
                                    ))}
                                </select>
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div class="form-group">
                                <label>PIN Code</label>{" "}
                                <span className="red">*</span>
                                <input
                                  type="text"
                                  class="form-control uppercase"
                                  placeholder="PIN Code"
                                  name="resPin"
                                  value={panData.resPin}
                                  onChange={handleInputChange}
                                  onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                  maxLength={6}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="pt-2 pb-3">
                            <hr />
                            <h5>
                              <b>Representative Assessee (RA)</b>
                            </h5>
                          </div>

                          <div className="row">
                            <div className="col-sm-4">
                              <div class="form-group">
                                <label>
                                  Minor Case <span className="red">*</span>
                                </label>
                                <select
                                  className="form-control uppercase"
                                  name="minorCase"
                                  value={panData.minorCase}
                                  onChange={handleInputChange}
                                >
                                  <option value="">Select</option>
                                  <option value="Y">YES</option>
                                  <option value="N" selected>
                                    NO
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>

                          {panData.minorCase == "Y" && (
                            <div>
                              <div className="row">
                                <div className="col-sm-4">
                                  <div class="form-group">
                                    <label for="exampleInputEmail1">
                                      Title <span className="red">*</span>
                                    </label>
                                    <select
                                      className="form-control uppercase"
                                      name="repTitle"
                                      value={panData.repTitle}
                                      onChange={handleInputChange}
                                    >
                                      <option value="">Select Title</option>
                                      <option value="1">SHRI</option>
                                      <option value="2">SMT</option>
                                      <option value="3">KUMARI</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-sm-4">
                                  <div class="form-group">
                                    <label>
                                      Last Name/Surname{" "}
                                      <span className="red">*</span>{" "}
                                    </label>
                                    <input
                                      type="text"
                                      class="form-control uppercase"
                                      placeholder="Last Name/Surname"
                                      name="repLast"
                                      value={panData.repLast}
                                      onChange={(e) => {
                                        handleInputChange(e);
                                      }}
                                    />
                                  </div>
                                </div>

                                <div className="col-sm-4">
                                  <div class="form-group">
                                    <label>First Name</label>
                                    <input
                                      type="text"
                                      class="form-control uppercase"
                                      placeholder="First name"
                                      name="repFirst"
                                      value={panData.repFirst}
                                      onChange={(e) => {
                                        handleInputChange(e);
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-sm-4">
                                  <div class="form-group">
                                    <label>Middle Name</label>
                                    <input
                                      type="text"
                                      class="form-control uppercase"
                                      placeholder="Middle name"
                                      name="repMiddle"
                                      value={panData.repMiddle}
                                      onChange={(e) => {
                                        handleInputChange(e);
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-sm-4">
                                  <div class="form-group">
                                    <label>
                                      Flat/Room/Door/Block No{" "}
                                      <span className="red">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      class="form-control uppercase"
                                      placeholder="Flat/Room/Door/Block No"
                                      name="repAddr1"
                                      value={panData.repAddr1}
                                      onChange={handleInputChange}
                                    />
                                  </div>
                                </div>
                                <div className="col-sm-4">
                                  <div class="form-group">
                                    <label>Building/Village Name</label>{" "}
                                    <span className="red">*</span>
                                    <input
                                      type="text"
                                      class="form-control uppercase"
                                      placeholder="Building/Village Name"
                                      name="repAddr2"
                                      value={panData.repAddr2}
                                      onChange={handleInputChange}
                                    />
                                  </div>
                                </div>
                                <div className="col-sm-4">
                                  <div class="form-group">
                                    <label>Road/Street/Lane/Post Office</label>{" "}
                                    <span className="red">*</span>
                                    <input
                                      type="text"
                                      class="form-control uppercase"
                                      placeholder="Road/Street/Lane/Post Office"
                                      name="repAddr3"
                                      value={panData.repAddr3}
                                      onChange={handleInputChange}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-sm-4">
                                  <div class="form-group">
                                    <label>Area/Locality/Sub-Division</label>{" "}
                                    <span className="red">*</span>
                                    <input
                                      type="text"
                                      class="form-control uppercase"
                                      placeholder="Area/Locality/Sub-Division"
                                      name="repAddr4"
                                      value={panData.repAddr4}
                                      onChange={handleInputChange}
                                    />
                                  </div>
                                </div>
                                <div className="col-sm-4">
                                  <div class="form-group">
                                    <label>
                                      State/Union Territory{" "}
                                      <span className="red">*</span>
                                    </label>

                                    <select
                                      className="form-control uppercase"
                                      name="repState"
                                      value={panData.repState}
                                      onChange={(e) => {
                                        handleInputChange(e);
                                        handleGetDistrictList(e);
                                      }}
                                    >
                                      <option value="">Please Select</option>
                                      <option value="1">
                                        ANDAMAN AND NICOBAR ISLANDS
                                      </option>
                                      <option value="2">ANDHRA PRADESH</option>
                                      <option value="3">
                                        ARUNACHAL PRADESH
                                      </option>
                                      <option value="4">ASSAM</option>
                                      <option value="5">BIHAR</option>
                                      <option value="6">CHANDIGARH</option>
                                      <option value="33">CHHATTISGARH</option>
                                      <option value="7">
                                        DADRA AND NAGAR HAVELI
                                      </option>
                                      <option value="8">DAMAN AND DIU</option>
                                      <option value="9">DELHI</option>
                                      <option value="10">GOA</option>
                                      <option value="11">GUJARAT</option>
                                      <option value="12">HARYANA</option>
                                      <option value="13">
                                        HIMACHAL PRADESH
                                      </option>
                                      <option value="14">
                                        JAMMU AND KASHMIR
                                      </option>
                                      <option value="35">JHARKHAND</option>
                                      <option value="15">KARNATAKA</option>
                                      <option value="16">KERALA</option>
                                      <option value="37">LADAKH</option>
                                      <option value="17">LAKSHADWEEP</option>
                                      <option value="18">MADHYA PRADESH</option>
                                      <option value="19">MAHARASHTRA</option>
                                      <option value="20">MANIPUR</option>
                                      <option value="21">MEGHALAYA</option>
                                      <option value="22">MIZORAM</option>
                                      <option value="23">NAGALAND</option>
                                      <option value="24">ODISHA</option>
                                      <option value="99">OTHER</option>
                                      <option value="25">PONDICHERRY</option>
                                      <option value="26">PUNJAB</option>
                                      <option value="27">RAJASTHAN</option>
                                      <option value="28">SIKKIM</option>
                                      <option value="29">TAMIL NADU</option>
                                      <option value="36">TELANGANA</option>
                                      <option value="30">TRIPURA</option>
                                      <option value="31">UTTAR PRADESH</option>
                                      <option value="34">UTTARAKHAND</option>
                                      <option value="32">WEST BENGAL</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="col-sm-4">
                                  <div class="form-group">
                                    <label>Town/City/District</label>{" "}
                                    <span className="red">*</span>
                                    <select
                                      className="form-control uppercase"
                                      name="repDist"
                                      value={panData.repDist}
                                      onChange={handleInputChange}
                                    >
                                      {districtList &&
                                        districtList.map((district, index) => (
                                          <option key={index} value={district}>
                                            {district}
                                          </option>
                                        ))}
                                    </select>
                                  </div>
                                </div>
                                <div className="col-sm-4">
                                  <div class="form-group">
                                    <label>PIN Code</label>{" "}
                                    <span className="red">*</span>
                                    <input
                                      type="text"
                                      class="form-control uppercase"
                                      placeholder="PIN Code"
                                      name="repPin"
                                      value={panData.repPin}
                                      onChange={handleInputChange}
                                      onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                          event.preventDefault();
                                        }
                                      }}
                                      maxLength={6}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                          <div className="pt-2 pb-3">
                            <hr />
                          </div>

                          <div className="row">
                            <div className="col-sm-4">
                              <div class="form-group">
                                <label>Proof of Identity</label>{" "}
                                <span className="red">*</span>
                                <select className="form-control uppercase">
                                  <option selected>AADHAAR CARD</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div class="form-group">
                                <label>
                                  Proof of Address
                                  <span className="red">*</span>
                                </label>
                                <select className="form-control uppercase">
                                  <option selected>AADHAAR CARD</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div class="form-group">
                                <label>Proof of Birth</label>{" "}
                                <span className="red">*</span>
                                <select className="form-control uppercase">
                                  <option selected>AADHAAR CARD</option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <div className="pt-2 pb-3">
                            <hr />
                          </div>

                          <div className="row">
                            <div className="col-sm-4">
                              <div class="form-group">
                                <label>VLE Name</label>{" "}
                                <span className="red">*</span>
                                <input
                                  type="text"
                                  class="form-control uppercase"
                                  placeholder="Agent Name"
                                  name="verName"
                                  value={panData.verName}
                                  onChange={handleInputChange}
                                />
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div class="form-group">
                                <label>
                                  VLE Place
                                  <span className="red">*</span>
                                </label>
                                <input
                                  type="text"
                                  class="form-control uppercase"
                                  placeholder="VLE Place"
                                  name="verPlace"
                                  value={panData.verPlace}
                                  onChange={handleInputChange}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="pt-2 pb-3">
                            <hr />
                          </div>

                          <div className="row">
                            <div className="col-sm-4">
                              <div class="form-group">
                                <label>PAN Type</label>{" "}
                                <span className="red">*</span>
                                <select className="form-control uppercase">
                                  <option selected>Normal</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div class="form-group">
                                <label>Charges</label>{" "}
                                <span className="red">*</span>
                                <input
                                  type="text"
                                  class="form-control uppercase"
                                  placeholder="Processing Fee: ₹120"
                                  readOnly
                                />
                              </div>
                            </div>
                          </div>

                          <div className="pt-2 pb-3">
                            <hr />
                          </div>

                          <div style={alertStyle}>
                            <strong style={strongStyle}>Important Note:</strong>
                            <ul>
                              <li>
                                Once the form is submitted,{" "}
                                <strong>no refunds will be processed</strong>.
                              </li>
                              <li>
                                Ensure you upload all documents{" "}
                                <strong>correctly</strong> and{" "}
                                <strong>accurately</strong>.
                              </li>
                              <li>
                                We are <strong>not responsible</strong> for any
                                issues arising from the{" "}
                                <strong>entry of incorrect details</strong>.
                              </li>
                            </ul>
                            <p>
                              Please verify all information before submission.
                            </p>
                          </div>

                          {JSON.stringify(panData)}

                          <div class="text-center pb-3">
                            <button
                              type="button"
                              class="btn btn-primary"
                              onClick={submitHandler}
                            >
                              Pay & Submit PAN Form
                            </button>
                            <button type="reset" class="btn btn-default ml-5">
                              Reset
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Templete>
    </React.Fragment>
  );
};

export default UtiitslPanCardV2;
